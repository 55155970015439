<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="miniLoader"
          ></v-progress-circular>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
          ></v-progress-circular>
          <span v-else class="d-flex flex-row align-center">
            <h1><span>Order #{{this.data.orderId}} - </span>{{' '+this.singular}} #{{this.data.id}}</h1>
            <div>
              <span v-if="data.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
              <span v-if="data.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
              <span v-if="data.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
              <span v-if="data.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
              <span v-if="data.status===3" class="v-btn success pa-1" style="font-size: 10px">{{parseStatus(data.status)}}</span>
            </div>
          </span>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col cols="2" v-if="allowEditing">
          <span v-if="data.status!==-1 && data.status!==2 && data.status!==3">
            <h3>Products</h3>
            <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column">
              <div v-for="(product, i) in order.orderItems" :key="i">
                <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" v-if="!product.hidden" class="d-flex flex-row align-center justify-space-between">
                  <div>
                    <span>{{product.name}}</span>
                    <br><span><b>ID:</b> {{product.id}}</span>
                    <br><span><b>Model #:</b> {{product.sku}}</span>
                    <br><span><b>QTY:</b> {{product.quantity}}</span>
                  </div>
                  <v-btn @click="addProduct(i)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>
                </div>
              </div>
            </div>
          </span>
        </v-col>
        <v-col>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-card outlined class="pa-10">
                  <div v-if="customer">
                    <h3>Customer</h3>
                    <span><b>Name:</b> {{customer.name || (customer.first_name+' '+customer.last_name)}}</span>
                  </div>
                  <div v-if="order.id" class="d-flex flex-row">
                    <div class="d-flex flex-column px-3">
                      <span class="d-flex flex-column">
                        <h3 class="mb-2">Delivery Date</h3>
                        <v-menu
                            :close-on-content-click="false"
                            :disabled="!allowEditing"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="data.dueDate"
                                label="Delivery Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                dense
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!allowEditing"
                                @change="saveDraft"
                            />
                          </template>
                          <v-date-picker v-model="data.dueDate" @change="saveDraft"/>
                        </v-menu>
                      </span>
                      <span class="d-flex flex-column">
                        <h3>Contact</h3>
                        <v-text-field dense :disabled="!allowEditing" v-model="data.contact.person" @change="contactChanged" label="Name" outlined></v-text-field>
                        <v-text-field dense :disabled="!allowEditing" v-model="data.contact.phone" @change="contactChanged" label="Phone" outlined></v-text-field>
                      </span>
                    </div>
                    <div class="d-flex flex-column px-3">
                      <span class="d-flex flex-column">
                        <span class="d-flex flex-row">
                          <h3>Delivery Address</h3>
                          <v-btn class="ml-1" v-if="customer && allowEditing" color="info" x-small fab @click="addressDialog=true"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        </span>
                        <span class="d-flex flex-row mt-1">
                          <v-text-field dense class="mx-1" :disabled="!allowEditing" v-model="data.address.line1" @change="checkCustomAddress" label="Address Line 1" outlined></v-text-field>
                          <v-text-field dense class="mx-1" :disabled="!allowEditing" v-model="data.address.line2" @change="checkCustomAddress" label="Address Line 2" outlined></v-text-field>
                        </span>
                        <span class="d-flex flex-row">
                          <v-text-field dense class="mx-1" :disabled="!allowEditing" v-model="data.address.city" @change="checkCustomAddress" label="City" outlined></v-text-field>
                          <v-text-field dense class="mx-1" :disabled="!allowEditing" v-model="data.address.country" @change="checkCustomAddress" label="Country" outlined></v-text-field>
                        </span>
                        <v-btn v-if="data.address.customAddress" color="info" @click="saveCustomAddressDialog=true" small>Save Custom Address to Customer</v-btn>
                      </span>
<!--                      <span class="d-flex flex-column">-->
<!--                        <h3>Delivery Charges</h3>-->
<!--                        <span class="d-flex flex-row">-->
<!--                          <v-text-field :disabled="!allowEditing" dense v-model="data.cost" label="Delivery Cost" type="number" outlined></v-text-field>-->
<!--                        </span>-->
<!--                      </span>-->
                    </div>
                  </div>
                  <span v-if="data.deliveryItems.length>0">
                    <h2>Delivery Items</h2>
                    <v-card class="d-flex" :style="((selectedItem && selectedItem.id===item.id)?'background: #eeeeee':'')" outlined v-for="(item, i) in data.deliveryItems" :key="i">
                      <v-col class="d-flex flex-column">
                        {{item.name}}
                        <span><b>ID:</b>{{item.id}}</span>
                        <span><b>Model #:</b>{{item.sku}}</span>
                        <span><b>Delivery QTY: </b>{{item.quantity}}</span>
                      </v-col>
                      <v-col class="d-flex">
                        <v-text-field :min="1" @change="changeQty(i)" type="number" :disabled="!allowEditing" label="QTY" v-model="data.deliveryItems[i].deliveryQty"/>
                        <span>
                          <v-btn
                              class="mx-2"
                              fab
                              x-small
                              dark
                              color="orange"
                              @click="showLocations(i)"
                          >
                            <v-icon>mdi-warehouse</v-icon>
                          </v-btn>
                          <v-btn v-if="allowEditing && item.deliveryQty!==item.qtyToAdd" style="font-size: 10px; width: 20px; height: 20px; margin-left: -20px; margin-top: -8px;" @click="showLocations(i)" fab x-small dark color="blue"><v-icon x-small>mdi-exclamation</v-icon></v-btn>
                        </span>
<!--                        <span v-if="data.status>=1 || data.status===-1">-->
<!--                          <v-btn-->
<!--                              class="mx-2"-->
<!--                              fab-->
<!--                              x-small-->
<!--                              dark-->
<!--                              color="primary"-->
<!--                              @click="showReturns(i)"-->
<!--                          >-->
<!--                            <v-icon>mdi-package-down</v-icon>-->
<!--                          </v-btn>-->
<!--                        </span>-->
                        <v-btn
                            v-if="allowEditing"
                            class="mx-2"
                            fab
                            x-small
                            dark
                            color="red"
                            @click="removeProduct(i)"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-card>
                    <div>
                      <v-col>
                        <v-text-field :disabled="!allowEditing" label="Notes" v-model="data.metadata.notes" @change="saveDraft"/>
                      </v-col>
                    </div>
                  </span>
                  <v-card-actions>
                    <div v-if="data.status>=0 && data.status<2">
                      <v-btn v-if="data.status!==2 && data.status!==3" class="mr-1" color="error" @click="confirmVoidDialog=true">Void {{singular}}</v-btn>
                      <v-btn v-if="data.status===0" :disabled="allDataSet===false || data.status===1 || data.status===-1" class="mr-1" @click="confirmSaveDialog=true" color="success">Save & Seal</v-btn>
                      <v-btn v-if="data.status===1" class="mr-1" @click="confirmDeliveredDialog=true" color="info">Mark as Delivered</v-btn>
                    </div>
                    <div v-if="data.status===100">
                      <v-btn color="error" @click="() => this.$router.go(-1)">Cancel</v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="3" v-if="selectedItem">
          <h3>Locations for</h3>
          <span>{{selectedItem.name}}</span>
          <br>
          <b>ID:</b>{{selectedItem.id}}
          <br>
          <b>Model #:</b>{{selectedItem.sku}}
          <br>
          <span v-if="allowEditing"><b>QTY to Add:</b>{{selectedItem.deliveryQty-selectedItem.qtyToAdd}}</span>
          <br>
          <span class="red--text" v-if="this.selectedItem.qtyToAdd>=this.selectedItem.deliveryQty">Total number of items to have been assigned locations.</span>
          <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column align-center">
            <v-progress-circular
                indeterminate
                color="green"
                v-if="locationLoading"
            ></v-progress-circular>
            <div v-else v-for="(location, i) in selectedItem.locations" :key="i" class="d-flex flex-row" style="width: 100%;">
              <v-card class="d-flex flex-column px-5" outlined style="width: 100%;">
                <h4 class="text-center">{{location.Room.Warehouse.name+' '+location.Room.name}}</h4>
                <span v-if="location.quantity>0" class="d-flex flex-row pt-2 mb-2" :style="((allowEditing && location.customQuantity>0)?'background: #eeeeee':'')">
                  <v-text-field :min="0" @change="updateLocation(i, 'customQuantity')" type="number" :rules="quantityRules" :disabled="!allowEditing" :label="`New QTY ${(data.status===100 || data.status===0)?`(${location.quantity})`:''}`" v-model="location.customQuantity"/>
                </span>
                <v-spacer></v-spacer>
                <span v-if="location.usedQuantity>0" class="d-flex flex-row pt-2 mb-2" :style="((allowEditing && location.customUsedQuantity>0)?'background: #eeeeee':'')">
                  <v-text-field :min="0" @change="updateLocation(i, 'customUsedQuantity')" type="number" :rules="quantityRules" :disabled="!allowEditing" :label="`Used QTY ${(data.status===100 || data.status===0)?`(${location.usedQuantity})`:''}`" v-model="location.customUsedQuantity"/>
                </span>
              </v-card>
            </div>
          </div>
        </v-col>
<!--        <v-col cols="3" v-if="selectedItem2">-->
<!--          <h3>Return Locations</h3>-->
<!--          <span>{{selectedItem2.name}}</span>-->
<!--          <br>-->
<!--          <b>ID:</b>{{selectedItem2.id}}-->
<!--          <br>-->
<!--          <b>Model #:</b>{{selectedItem2.sku}}-->
<!--          <br>-->
<!--          <span><b>QTY Returning:</b>{{selectedItem2.qtyToReturn}}</span>-->
<!--          <br>-->
<!--          <span class="red&#45;&#45;text" v-if="this.selectedItem2.qtyToReturn>=this.selectedItem2.deliveryQty">Maximum quantity of this product to return has been reached.</span>-->
<!--          <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column align-center">-->
<!--            <v-progress-circular-->
<!--                indeterminate-->
<!--                color="green"-->
<!--                v-if="locationLoading"-->
<!--            ></v-progress-circular>-->
<!--            <div v-else v-for="(location, i) in selectedItem2.returnLocations" :key="i" class="d-flex flex-row">-->
<!--              <v-card class="d-flex flex-column px-5" outlined>-->
<!--                <h4 class="text-center">{{location.name}}</h4>-->
<!--                <span v-if="location.customQuantity!==undefined" class="d-flex flex-row pt-2 mb-2" :style="((allowEditingAfterSeal && location.customQuantity>0)?'background: #eeeeee':'')">-->
<!--                  <v-btn-->
<!--                      class="mx-2"-->
<!--                      fab-->
<!--                      x-small-->
<!--                      dark-->
<!--                      color="success"-->
<!--                      :disabled="location.customQuantity===0 || !allowEditingAfterSeal"-->
<!--                      @click="updateReturnLocation(i, -1, 'customQuantity')"-->
<!--                  ><v-icon>mdi-minus</v-icon></v-btn>-->
<!--                  <v-text-field @change="updateReturnLocation(i, 0, 'customQuantity')" type="number" :rules="quantityRules" :disabled="!allowEditingAfterSeal" :label="`${location.name.toLowerCase().includes('destroyed')?'Qty':'New QTY'}`" v-model="location.customQuantity"/>-->
<!--                  <v-btn-->
<!--                      class="mx-2"-->
<!--                      fab-->
<!--                      x-small-->
<!--                      dark-->
<!--                      color="success"-->
<!--                      :disabled="selectedItem2.qtyToReturn>=selectedItem2.deliveryQty || !allowEditingAfterSeal"-->
<!--                      @click="updateReturnLocation(i, 1, 'customQuantity')"-->
<!--                  ><v-icon>mdi-plus</v-icon></v-btn>-->
<!--                </span>-->
<!--                <v-spacer></v-spacer>-->
<!--                <span v-if="location.customUsedQuantity!==undefined" class="d-flex flex-row pt-2 mb-2" :style="((allowEditingAfterSeal && location.customUsedQuantity>0)?'background: #eeeeee':'')">-->
<!--                  <v-btn-->
<!--                      class="mx-2"-->
<!--                      fab-->
<!--                      x-small-->
<!--                      dark-->
<!--                      color="success"-->
<!--                      :disabled="location.customUsedQuantity===0 || !allowEditingAfterSeal"-->
<!--                      @click="updateReturnLocation(i, -1, 'customUsedQuantity')"-->
<!--                  ><v-icon>mdi-minus</v-icon></v-btn>-->
<!--                  <v-text-field @change="updateReturnLocation(i, 0, 'customUsedQuantity')" type="number" :rules="quantityRules" :disabled="!allowEditingAfterSeal" :label="`Used QTY`" v-model="location.customUsedQuantity"/>-->
<!--                  <v-btn-->
<!--                      class="mx-2"-->
<!--                      fab-->
<!--                      x-small-->
<!--                      dark-->
<!--                      color="success"-->
<!--                      :disabled="selectedItem2.qtyToReturn>=selectedItem2.deliveryQty || !allowEditingAfterSeal"-->
<!--                      @click="updateReturnLocation(i, 1, 'customUsedQuantity')"-->
<!--                  ><v-icon>mdi-plus</v-icon></v-btn>-->
<!--                </span>-->
<!--              </v-card>-->
<!--            </div>-->
<!--          </div>-->
<!--        </v-col>-->
      </v-row>
    </v-container>
    <v-dialog
        v-model="confirmSaveDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Enter your password to confirm this change.
        </v-card-title>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="confirmSaveDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :disabled="!userPassword"
              @click="saveDelivery"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="confirmDeliveredDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Enter your password to confirm this change.
        </v-card-title>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              text
              @click="confirmDeliveredDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="success"
              :disabled="!userPassword"
              @click="markAsDelivered"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="confirmVoidDialog"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Enter your password to confirm this change.
        </v-card-title>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="confirmVoidDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
              color="error"
              :disabled="!userPassword"
              @click="voidDelivery"
          >
            Void
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model.lazy="printDialog.isOpen"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print Delivery Note
        </v-card-title>
        <v-card-text>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
          <v-text-field style="margin-top: 10px;" dense v-model="printDialog.password" type="password" inputmode="numeric" label="Password" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="attemptPrintDialog()" :loading="printDialog.isPrinting">
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="printBarcodeDialog.isOpen"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print Product Barcodes
        </v-card-title>
        <v-card-text>
          This will print all the barcodes for this report.
          <v-text-field style="margin-top: 10px;" dense v-model="printBarcodeDialog.password" type="password" label="Password" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelBarcodeDialog()">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="attemptBarcodePrint()">
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="addressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Customer Addresses
        </v-card-title>
        <v-card-text v-if="customer && customer.addresses && customer.addresses.length>0">
          <v-card :key="i" v-for="(addr, i) in customer.addresses" class="d-flex flex-row pa-2 justify-space-between mb-2" outlined>
            <span class="d-flex flex-column text-left">
              <h3 v-if="addr.name">{{addr.name}}</h3>
              <span v-if="addr.line1"><b>Address: </b>{{addr.line1}}{{addr.line2?", "+addr.line2:""}}</span>
              <span v-if="addr.city"><b>Town/City: </b>{{addr.city}}</span>
              <span v-if="addr.country"><b>Country: </b>{{addr.country}}</span>
            </span>
            <v-btn @click="setAddress(addr)" fab x-small color="success"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card>
        </v-card-text>
        <v-card-text v-else>
          <span>This customer has no addresses.</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeAddressDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Custom Address
        </v-card-title>
        <v-card-text>
          <v-text-field type="text" dense outlined label="Name of Address" v-model="customAddressName"/>
          <div class="d-flex flex-column">
            <span v-if="data.address.line1">{{data.address.line1}}<span v-if="data.address.line2">, {{data.address.line2}}</span></span>
            <span v-if="data.address.city">{{data.address.city}}<span v-if="data.address.country">, {{data.address.country}}</span></span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
export default {
  data () {
    return {
      loader: false,
      miniLoader: false,
      locationLoading: false,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      returnLink: '/orders',
      max25chars: v => v.length <= 25 || 'Input too long!',
      data: {
        deliveryItems: [],
        address: {},
        contact: {},

        cost: 0,
        status: 0,
        orderId: null,
        metadata: {}
      },
      lockOrder: false,
      orderId: null,
      order: {
        orderItems: []
      },
      printDialog: {
        isOpen: false,
        dnId: '',
        adminId: '',
        quantity: '',
        password: '',
        isPrinting: false,
        type: '',
      },
      customer: null,
      addressDialog: false,
      saveCustomAddressDialog: false,
      customAddressName: "",

      quantityRules: [
        v => v>=0 || 'Must be more than or equal 0'
      ],

      allDataSet: false,

      warehouses: [],
      rooms: [],
      selectedItem: null,
      locationsNote: "",

      confirmSaveDialog: false,
      confirmVoidDialog: false,
      confirmDeliveredDialog: false,
      confirmCompletedDialog: false,
      userPassword: "",
      singular: "Delivery",
      singularLower: "delivery",
      plural: "Deliveries",
      pluralLower: "deliveries",
      printBarcodeDialog: {
        isOpen: false,
        password: '',
        adminId: ''
      }
    }
  },
  async mounted(){
    try{
      this.loader = true;

      let data = await axios.get(`${this.getEndpoint}/api/warehouses/withRooms`)
      if(data.data.error) throw data.data.error
      this.warehouses = data.data.data;

      let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
      if(res.data.error) throw res.data.error
      this.data = res.data.data;

      if(this.data.dueDate){
        this.data.dueDate = new Date(this.data.dueDate).toISOString().substring(0, 10);
      }

      if(!this.data.metadata) this.data.metadata = {};

      this.orderId = this.data.orderId;

      let order = await axios.get(`${this.getEndpoint}/api/orders/${this.orderId}`)
      if(order.data.error) throw order.data.error
      this.order = order.data.data;

      this.data.orderId = this.order.id;
      this.lockOrder = true;

      let customer = await axios.get(`${this.getEndpoint}/api/customers/${this.order.customerId}`)
      if(customer.data.error) throw customer.data.error
      this.customer = customer.data.data;

      this.returnLink = `/orders/view/${this.order.id}`;

      this.data.deliveryItems.forEach(item => {
        let oItem = this.order.orderItems.find(val => {
          return val.id===item.id;
        });
        oItem.hidden = true;
      });

      if(this.data.status===0){
        for(let item of this.data.deliveryItems){
          let locationsData = await axios.get(`${this.getEndpoint}/api/products/${item.id}/onlyLocations`)
          if(locationsData.data.error) throw locationsData.data.error

          let locations = locationsData.data.data.ProductRoomJoins;

          for(let location of locations){
            let existing = item.locations.findIndex(x => {
              return location.roomId===x.roomId;
            });
            if(existing!==-1){
              location.customQuantity = item.locations[existing].customQuantity;
              location.customUsedQuantity = item.locations[existing].customUsedQuantity;
              item.locations[existing] = location;
            }
          }
        }
      }

      this.checkAllData();
    }
    catch (error) {
      console.error(error)
      this.snack(error.msg || error, "error");
    }
    finally {
      this.loader = false;
    }
  },
  computed: {
    ...mapGetters(['getId', 'getEndpoint', "isAllowed"]),
    allowEditing(){
      let x = (this.data.status===0);
      return x;
    },
    allowEditingAfterSeal(){
      let x = (this.data.status===0 || this.data.status===1);
      return x;
    }
  },
  methods: {
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    openPrintBarcodeDialog(){
      this.printBarcodeDialog.isOpen = true
    },
    cancelBarcodeDialog(){
      this.printBarcodeDialog.isOpen = false
      this.printBarcodeDialog.password = ""
    },
    async attemptBarcodePrint(){
      try {
        this.printBarcodeDialog.adminId = this.getId
        let res = await axios.post(`${this.getEndpoint}/api/products/printBarcodesForReceivingReport/${this.$route.params.id}`, this.printBarcodeDialog)
        if(res.data.error) throw res.data.error

        this.snack("Print Job Successfully Posted.")
        this.cancelBarcodeDialog()
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    // async generateReturnLocations(){
    //   let returnedItems = [];
    //   for(let item of this.data.deliveryItems){
    //     let locations = [];
    //
    //     this.warehouses.forEach(warehouse => {
    //       warehouse.Rooms.forEach(room => {
    //         let obj = {
    //           roomId: room.id,
    //           name: warehouse.name+' - '+room.name,
    //           customQuantity: 0,
    //           customUsedQuantity: 0,
    //           type: item.type
    //         }
    //
    //         locations.push(obj);
    //       });
    //     });
    //
    //     let res = await axios.get(`${this.getEndpoint}/api/rooms/destroyedRoom`)
    //     if(res.status!==200) throw "Destroyed Room could not be found."
    //     let dRoom = res.data;
    //     if(!dRoom) throw "Destroyed Room could not be found";
    //
    //     let destroyedRoom = {
    //       roomId: dRoom.id,
    //       name: "Destroyed Items",
    //       customQuantity: 0,
    //     }
    //
    //     locations.push(destroyedRoom);
    //
    //     let itemObj = {
    //       id: item.id,
    //       name: item.name,
    //       sku: item.sku,
    //       type: item.type,
    //       quantity: item.quantity,
    //       deliveryQty: item.deliveryQty,
    //       trueIndex: item.trueIndex,
    //       returnQty: 0,
    //       qtyToReturn: 0,
    //       returnLocations: locations
    //     }
    //
    //     returnedItems.push(itemObj);
    //   }
    //
    //   this.data.returnedItems = returnedItems;
    //
    //   this.$forceUpdate();
    // },
    async fetchLocationData(id){
      try{
        this.locationLoading = true;
        let res = await axios.get(`${this.getEndpoint}/api/products/${id}/onlyLocations`)
        if(res.data.error) throw res.data.error

        let locations = res.data.data.ProductRoomJoins;

        locations.forEach(x => {
          x.customQuantity = 0;
          x.customUsedQuantity = 0;
        });

        return locations;
      }
      catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.locationLoading = false;
      }
    },
    showLocations(i){
      this.selectedItem = this.data.deliveryItems[i];
    },
    async addProduct(i){
      let item = {
        id: this.order.orderItems[i].id,
        name: this.order.orderItems[i].name,
        sku: this.order.orderItems[i].sku,
        type: this.order.orderItems[i].type,
        quantity: this.order.orderItems[i].quantity,
        deliveryQty: 1,
        locations: [],
        qtyToAdd: 0,
        trueIndex: i,
        loading: false
      };

      this.data.deliveryItems.push(item);
      this.order.orderItems[i].hidden = true;

      let newLocations = await this.fetchLocationData(this.order.orderItems[i].id);
      item.locations = newLocations;

      await this.saveDraft();
    },
    async removeProduct(i){
      this.order.orderItems[this.data.deliveryItems[i].trueIndex].hidden = false;
      if(this.selectedItem && this.selectedItem.id===this.data.deliveryItems[i].id){
        this.selectedItem = null;
      }
      this.data.deliveryItems.splice(i, 1);


      await this.saveDraft();
    },
    async changeQty(i){
      try{
        this.data.deliveryItems[i].deliveryQty=parseInt(this.data.deliveryItems[i].deliveryQty);
        this.data.deliveryItems[i].locations = await this.fetchLocationData(this.data.deliveryItems[i].id);
        this.data.deliveryItems[i].qtyToAdd = 0;

        if(this.data.deliveryItems[i].deliveryQty<1){
          this.data.deliveryItems[i].deliveryQty=1;
          this.snack("Quantity must be at least 1.")

        }
        if(this.data.deliveryItems[i].deliveryQty>this.data.deliveryItems[i].quantity){
          this.data.deliveryItems[i].deliveryQty=parseInt(this.data.deliveryItems[i].quantity);
          this.snack(`Quantity cannot surpass ordered amount. (MAX: ${this.data.deliveryItems[i].quantity})`)
        }

        await this.saveDraft();
      }
      catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
      }
    },
    updateLocation(i, field){
      let defField = (field.split("custom")[1]).charAt(0).toLowerCase()+(field.split("custom")[1].slice(1));
      this.selectedItem.locations[i][field] = parseInt(this.selectedItem.locations[i][field]);

      if(this.selectedItem.locations[i][field]<0){
        this.selectedItem.locations[i][field]=0;
        this.snack("Quantity cannot be less an 0.")
      }

      if(this.selectedItem.locations[i][field]>this.selectedItem.locations[i][defField]){
        this.selectedItem.locations[i][field]=0;
        this.snack("Room quantity is not enough.")
      }

      let total = 0;
      for(let location of this.selectedItem.locations){
        total+=location.customQuantity;
        total+=location.customUsedQuantity;
      }

      this.selectedItem.qtyToAdd = total;
      if(total>this.selectedItem.deliveryQty){
        let without = this.selectedItem.deliveryQty-(total-this.selectedItem.locations[i][field])
        this.selectedItem.qtyToAdd=this.selectedItem.deliveryQty;
        this.selectedItem.locations[i][field] = without;
        this.snack("Total surpassed. Quantity adjusted.")
      }

      this.$forceUpdate()
      this.saveDraft();
    },
    // updateReturnLocation(i, qty, field){
    //   let defField = (field.split("custom")[1]).charAt(0).toLowerCase()+(field.split("custom")[1].slice(1));
    //
    //   this.selectedItem2.returnLocations[i][field] = parseInt(this.selectedItem2.returnLocations[i][field])+qty;
    //
    //   if(this.selectedItem2.returnLocations[i][field]<0){
    //     this.selectedItem2.returnLocations[i][field]=0;
    //     return;
    //   }
    //
    //   if(this.selectedItem2.returnLocations[i][field]>this.selectedItem2.returnLocations[i][defField]){
    //     this.selectedItem2.returnLocations[i][field]=parseInt(this.selectedItem2.returnLocations[i][defField]);
    //     return;
    //   }
    //
    //   let total = 0;
    //   for(let location of this.selectedItem2.returnLocations){
    //     total+=(location.customQuantity || 0);
    //     total+=(location.customUsedQuantity || 0);
    //   }
    //   this.selectedItem2.qtyToReturn = total;
    //
    //   if(total>this.selectedItem2.deliveryQty){
    //     this.selectedItem2.qtyToReturn-=parseInt(this.selectedItem2.returnLocations[i][field]);
    //     this.selectedItem2.returnLocations[i][field] = 0;
    //     return;
    //   }
    // },
    checkAllData(){
      for(let item of this.data.deliveryItems){
        if(item.deliveryQty!==item.qtyToAdd){
          this.allDataSet = false;
          return;
        }
      }
      if(!(this.data.dueDate &&
          (this.data.address.line1 && this.data.address.line1.length>0) &&
          (this.data.address.city && this.data.address.city.length>0))
      ){
        this.allDataSet = false;
        return;
      }
      this.allDataSet = true;
    },
    async fetchOrder(){
      try{
        if(!this.orderId) throw "No Order ID provided.";
        let res = await axios.get(`${this.getEndpoint}/api/orders/${this.orderId}`)
        if(res.data.error) throw res.data.error

        if(res.data.data.status!==2) throw `Order #${this.orderId} is not Admin sealed.`

        this.order = res.data.data;
        this.data.orderId = this.order.id;

        let paths = this.$route.path.split("/");
        if(paths[paths.length-1]==="createdelivery"){
          this.data.address=this.order.deliveryInfo.address || {}
        }

        let customer = await axios.get(`${this.getEndpoint}/api/customers/${this.order.customerId}`)
        if(customer.data.error) throw customer.data.error
        this.customer = customer.data.data;

        this.returnLink = `/orders/view/${this.order.id}`;
      }
      catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
        await this.$router.go(-1);
      }
    },
    async saveDraft(){
      try{
        this.miniLoader = true;

        this.checkAllData();

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`, this.data)
        if(res.data.error) throw res.data.error
      }
      catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.miniLoader = false;
      }
    },
    async deleteDraft(){
      try{
        this.data.createdBy = this.getId;
        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.data.id}`, this.data)
        if(res.data.error) throw res.data.error

        this.data.status = -1;
        this.snack(`${this.singular} deleted.`, "success")

        await this.$router.go(-1);
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    async saveDelivery(){
      try{
        await this.cleanData();

        this.data.sealedBy = this.getId;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/save/${this.data.id}`, {data: this.data, password: this.userPassword})
        if(res.data.error) throw res.data.error

        this.data = res.data.data;

        if(this.data.dueDate){
          this.data.dueDate = new Date(this.data.dueDate).toISOString().substring(0, 10);
        }

        this.snack(`${this.singular} sealed.`, "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.userPassword = "";
        this.confirmSaveDialog = false;
      }
    },
    async markAsDelivered(){
      try{
        await this.cleanData();

        this.data.deliveredBy = this.getId;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/deliver/${this.data.id}`, {data: this.data, password: this.userPassword})
        if(res.data.error) throw res.data.error

        this.data = res.data.data;

        if(this.data.dueDate){
          this.data.dueDate = new Date(this.data.dueDate).toISOString().substring(0, 10);
        }

        this.snack(`${this.singular} marked as delivered.`, "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.userPassword = "";
        this.confirmDeliveredDialog = false;
      }
    },
    async voidDelivery(){
      try{
        await this.cleanData();

        this.data.voidedBy = this.getId;

        let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.data.id}`, {password: this.userPassword})
        if(res.data.error) throw res.data.error

        this.data = res.data.data;

        if(this.data.dueDate){
          this.data.dueDate = new Date(this.data.dueDate).toISOString().substring(0, 10);
        }

        this.data.status = -1;
        this.snack(`${this.singular} voided.`, "success")
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.userPassword = "";
        this.confirmVoidDialog = false;
      }
    },
    async cleanData(){
      this.data.deliveryItems.forEach(item => {
        for(let i=item.locations.length-1; i>=0; i--){
          if(item.locations[i].customQuantity===0 && item.locations[i].customUsedQuantity===0){
            item.locations.splice(i, 1);
          }
        }
      });

      // this.data.returnedItems.forEach(item => {
      //   for(let i=item.returnLocations.length-1; i>=0; i--){
      //     if((item.returnLocations[i].customQuantity!==undefined && item.returnLocations[i].customQuantity===0) && (item.returnLocations[i].customUsedQuantity!==undefined && item.returnLocations[i].customUsedQuantity===0)){
      //       item.returnLocations.splice(i, 1);
      //     }
      //   }
      // });
    },
    async setAddress(addr){
      this.data.address = {line1: addr.line1, line2: addr.line2, city: addr.city, country: addr.country, name: addr.name};
      this.data.address.customAddress = false;

      await this.saveDraft();
      this.closeAddressDialog();
    },
    closeAddressDialog(){
      this.addressDialog = false;
    },
    async saveCustomAddress(){
      try{
        if(this.customAddressName.length===0) throw "A name is required."
        let addr = {...this.data.address};
        addr.name = this.customAddressName;
        let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.customer.id}`, addr)
        if(res.data.error) throw res.data.error

        this.snack("Address added successfully!");
        this.data.address.customAddress = false;
        this.customer.addresses.push({line1: addr.line1, line2: addr.line2, city: addr.city, country: addr.country, name: addr.name});
        this.closeCustomAddressDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
    },
    checkCustomAddress(){
      let val = true;
      if(this.customer && this.customer.addresses){
        for(let addr of this.customer.addresses){ 
          if((this.data.address.line1===addr.line1 &&
                  this.data.address.line2===addr.line2 &&
                  this.data.address.city===addr.city &&
                  this.data.address.country===addr.country) ||
              (this.data.address.line1==="" &&
                  this.data.address.line2==="" &&
                  this.data.address.city==="" &&
                  this.data.address.country==="")){
            val = false;
          }
        }
      }
      else{
        val = false;
      }
      this.data.address.customAddress = val;
      this.$forceUpdate();
    },
    closeCustomAddressDialog(){
      this.customAddressName = "";
      this.saveCustomAddressDialog = false;
    },
    async contactChanged(){
      await this.saveDraft();
    },
    parseStatus(id){
      if(id===-1) return "Voided"
      else if(id===0) return "Draft"
      else if(id===1) return "Sealed"
      else if(id===2) return "Delivered"
    },
    openPrintDialog(){
      this.printDialog.isOpen = true
    },
    closePrintDialog(){
      this.printDialog = {
        isOpen: false,
        dnId: '',
        adminId: '',
        quantity: '',
        password: '',
        isPrinting: false,
      }
    },
    async attemptPrintDialog(){
      try {
        this.printDialog.adminId = this.getId
        this.printDialog.customer = this.customer
        // this.printDialog.isPrinting = true
        let res = await axios.post(`${this.getEndpoint}/api/orders/printDN/${this.$route.params.id}`, this.printDialog)
        if(res.data.error) throw res.data.error

        this.snack("✅🖨 Delivery Note Printing.")
      } catch (error) {
        console.error(error)
      }finally{
        // this.closePrintDialog()
      }
    },
  }
}
</script>
