<template>
  <div class="" style="width: 100%;">
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row>
            <v-col class="d-flex flex-row align-center">
              <h1>{{this.plural}}</h1>
              <v-progress-circular
                  indeterminate
                  color="green"
                  v-if="loader"
                  style="margin-left: 10px;"
              ></v-progress-circular>
              <v-btn small fab color="info" @click="openCreateInvoiceDialog" class="ml-2"><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="!loader">
              <v-card outlined :key="i" v-for="(order, i) in allData" class="mb-2 d-flex flex-row align-center">
                <orderItem :order="order" />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-dialog
      v-model="createInvoiceDialog.isOpen"
      width="600"
      fixed
    >
      <v-card>
        <v-card-title class="text-h5 d-flex flex-column align-center justify-center">
          <b>Confirm Create Invoice</b>
        </v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="closeCreateInvoiceDialog">Cancel</v-btn>
          <v-btn color="success" text @click="attemptCreateInvoice()">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  import orderItem from '../../components/orderItem.vue'
  export default {
    components: {
      orderItem
    },
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          {
            text: '#',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Customer', value: 'customerName' },
          { text: 'CSR', value: 'CSRName' },
          { text: 'Status', value: 'status' },
          { text: 'Delivery Type', value: 'deliveryInfo.deliveryType' },
          { text: '# Items', value: 'itemCount' },
          { text: 'Total', value: 'metadata.grandTotal' },
          // { text: 'Actions', value: 'actions' },
        ],
        itemHeaders: [
          { text: 'SKU', value: 'sku' },
          { text: 'Name', value: 'name' },
          { text: 'QTY', value: 'quantity' },
          { text: 'Unit Price', value: 'unitPrice' },
          { text: 'Line Total', value: 'lineTotal' },
        ],
        editMode: false,

        selectedOrder: null,

        data: {},
        allData: [],
        singular: "Order",
        singularLower: "order",
        plural: "Orders",
        pluralLower: "orders",

        createInvoiceDialog: {
          isOpen: false,
          customerId: ''
        }
      }
    },
    async mounted(){
        await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed','getUser'])
    },
    methods: {
      ...mapMutations(["setSnack"]),
      async rowClick(row){
        if(!(this.$route.query.order && this.$route.query.order==row.id)) {
          window.scrollTo({top: 0});
          await this.$router.replace({path: `/${this.pluralLower}`, query: {order: row.id}});
          this.selectedOrder = row;
          console.log(this.selectedOrder)
        }
      },
      async closeRow(){
        await this.$router.replace({path: `/${this.pluralLower}`, query: null});
        this.selectedOrder = null;
      },
      openOrder(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      openCustomer(row){
        this.$router.push({ path: `/customers/view/${row}`})
      },
      openEmployee(row){
        this.$router.push({ path: `/users/view/${row}`})
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/tableData`)
          if(res.data.error) throw res.data.error
          this.allData = res.data.data
          if(this.$route.query.order){
            let id = parseInt(this.$route.query.order);
            this.selectedOrder = this.allData.find(x => x.id===id);
            if(!this.selectedOrder) this.closeRow()
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.loader = false;
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      openCreateInvoiceDialog(){
        this.createInvoiceDialog.isOpen = true
      },
      closeCreateInvoiceDialog(){
        this.createInvoiceDialog.isOpen = false
      },
      async attemptCreateInvoice(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/orders`, {createdBy: this.getUser.id})
          if(res.data.error) throw res.data.error
          await this.$router.push({path: `/orders/view/${res.data.data.id}`})
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>
